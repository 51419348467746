.slick-next::before {
  display: none;
}
.slick-prev::before {
  display: none;
}
.slick-slider .slick-dots {
  bottom: 0.75rem;
}
.slick-slider .slick-dots li {
  margin: 0;
  width: 16px;
  height: 16px;
}
.slick-slider .slick-dots li button {
  width: 16px;
  height: 16px;
  padding: 8px;
}
.slick-slider .slick-dots li button::before {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 6px;
}
