@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "FixBrokenEmoji", "Pretendard", "Apple SD Gothic Neo",
      -apple-system, BlinkMacSystemFont, Roboto, "Noto Sans KR", "Segoe UI",
      "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      sans-serif;
    font-size: 16px;
    padding: 0 !important;
  }

  body {
    margin: 0 !important;
    background-color: #13131a;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  mark {
    background-color: rgba(178, 255, 0, 0.24);
    color: inherit;
    border-radius: 0.25rem;
  }
}

@layer utilities {
  .flex-center {
    @apply flex items-center justify-center;
  }

  .flex-col-center {
    @apply flex flex-col justify-center;
  }

  .ciety-outline {
    @apply !outline !outline-2 !outline-offset-0 !outline-ciety-lg;
  }

  .typo-headline-m {
    @apply text-[1.5rem] font-medium leading-[130%] tracking-normal;
  }

  .typo-headline-r {
    @apply text-[1.5rem] font-normal leading-[130%] tracking-normal;
  }

  .typo-headline-m-20 {
    @apply text-[1.25rem] font-medium leading-[130%] tracking-normal;
  }

  .typo-headline-m-22 {
    @apply text-[1.375rem] font-medium leading-[130%] tracking-normal;
  }

  .typo-headline-m-24 {
    @apply text-[1.5rem] font-medium leading-[130%] tracking-normal;
  }

  .typo-headline-m-32 {
    @apply text-[2rem] font-medium leading-[130%] tracking-normal;
  }

  .typo-headline-r-22 {
    @apply text-[1.375rem] font-normal leading-[130%] tracking-normal;
  }

  .typo-m0 {
    @apply text-[1.125rem] font-medium leading-[150%] tracking-normal;
  }

  .typo-r0 {
    @apply text-[1.125rem] font-normal leading-[150%] tracking-normal;
  }

  .typo-m1 {
    @apply text-[1rem] font-medium leading-[150%] tracking-normal;
  }

  .typo-r1 {
    @apply text-[1rem] font-normal leading-[150%] tracking-normal;
  }

  .typo-m2 {
    @apply text-[0.875rem] font-medium leading-[150%] tracking-normal;
  }

  .typo-m2-130 {
    @apply text-[0.875rem] font-medium leading-[130%] tracking-normal;
  }

  .typo-r2 {
    @apply text-[0.875rem] font-normal leading-[150%] tracking-normal;
  }

  .typo-r2-130 {
    @apply text-[0.875rem] font-normal leading-[130%] tracking-normal;
  }

  .typo-r2-auto {
    @apply text-[0.875rem] font-normal tracking-normal;
  }

  .typo-m3 {
    @apply text-[0.75rem] font-medium leading-[135%] tracking-normal;
  }

  .typo-r3 {
    @apply text-[0.75rem] font-normal leading-[150%] tracking-normal;
  }

  .typo-r3-underline {
    @apply text-[0.75rem] font-normal leading-[135%] tracking-normal;
  }

  .typo-m4 {
    @apply text-[0.625rem] font-medium leading-[150%] tracking-normal;
  }

  .typo-r4 {
    @apply text-[0.625rem] font-normal leading-[125%] tracking-normal;
  }

  .typo-book-40 {
    @apply font-['ABCFavorit'] text-[2.5rem] font-[300] leading-[120%] tracking-normal;
  }

  .typo-book-20 {
    @apply font-['ABCFavorit'] text-[1.25rem] font-[300] leading-[150%] tracking-normal;
  }

  .typo-book-18 {
    @apply font-['ABCFavorit'] text-[1.125rem] font-[300] leading-[150%] tracking-normal;
  }

  .typo-book-14 {
    @apply font-['ABCFavorit'] text-[0.875rem] font-[300] leading-[120%] tracking-normal;
  }

  .typo-medium-16 {
    @apply font-['ABCFavorit'] text-[1rem] font-[500] leading-[120%] tracking-normal;
  }

  .typo-medium-20 {
    @apply font-['ABCFavorit'] text-[1.25rem] font-[500] leading-[100%] tracking-normal;
  }

  .typo-medium-28 {
    @apply font-['ABCFavorit'] text-[1.75rem] font-[350] leading-[120%] tracking-normal;
  }

  .typo-medium-30 {
    @apply font-['ABCFavorit'] text-[1.875rem] font-[500] leading-[100%] tracking-normal;
  }

  .typo-ciety-body {
    @apply font-['CIETY-BODY'] text-[9.375rem] font-normal leading-[50%] tracking-normal;
  }
}

a,
input,
textarea,
button {
  outline: none !important;
}

.is-redesigned-mobile
  *:not([contenteditable]):not([contenteditable] *):not(input):not(textarea) {
  user-select: none;
  -webkit-user-select: none;
}

#root {
  z-index: auto;
}

#headlessui-portal-root {
  position: relative;
  z-index: 40;
}

[contenteditable] {
  user-select: text;
  -webkit-user-select: text;
}

[contenteditable]:focus {
  outline: 0 solid transparent;
}

.overflow-anywhere {
  overflow-wrap: anywhere;
}

@media screen and (max-width: 1660px) and (min-width: 1160px) {
  html {
    font-size: 0.95vw;
  }
}
@media screen and (max-width: 1160px) {
  html:not(.is-mobile) {
    font-size: 11px;
    min-width: 1160px;
    overflow: auto;
  }
}

.emoji {
  font-family: "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji,
    "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji", EmojiSymbols, emoji;
}

@font-face {
  font-family: "FixBrokenEmoji";
  src: local("Apple Color Emoji"), local("Segoe UI Emoji");
  unicode-range: U+261D, U+2660, U+2665, U+2666, U+2663, U+2668, U+2708, U+260E,
    U+26A0, U+2B06, U+2197, U+27A1, U+2198, U+2B07, U+2199, U+2B05, U+2196,
    U+2195, U+2194, U+2934, U+2935, U+262F, U+25B6, U+25C0, U+23CF, U+2716,
    U+203C, U+2049, U+2611, U+2747, U+00A9, U+00AE, U+2122, U+1F170, U+1F171,
    U+24C2, U+1F17E, U+1F17F, U+25FC, U+25FB;
  /*
  ☝️♠️♥️♦️♣️️️♨️✈☎️
  ⚠️⬆️↗️➡️↘️⬇️↙️⬅️↖️↕️
  ↔️⤴️⤵️☯️▶️◀️⏏️✖️‼️⁉️
  ☑️❇️©️®️™️🅰️🅱️Ⓜ️🅾️🅿️
  ◼️◻️
  */
}

.ciety-scroll {
  overflow-x: hidden;
}

.ciety-scroll::-webkit-scrollbar {
  width: 0.3125rem;
}

.ciety-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.ciety-scroll::-webkit-scrollbar-thumb {
  border-radius: 0.1875rem;
  background-color: rgba(137, 137, 138, 0);
}

.ciety-scroll:hover::-webkit-scrollbar-thumb {
  background-color: rgba(137, 137, 138, 0.5);
}

.ciety-scroll::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

.ciety-no-style-scroll::-webkit-scrollbar {
  display: none;
}

:root {
  --modal-z-index: 100;

  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);

  --modal-z-index: 100;
}

.ciety-dm-profile2 > *:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
}
.ciety-dm-profile2 > *:nth-child(2) {
  position: absolute;
  bottom: 0;
  right: 0;
}

.ciety-dm-profile3 > *:nth-child(1) {
  position: absolute;
  bottom: 0;
  left: 0;
}
.ciety-dm-profile3 > *:nth-child(2) {
  position: absolute;
  bottom: 0;
  right: 0;
}
.ciety-dm-profile3 > *:nth-child(3) {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.ciety-dm-profile4 > *:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
}
.ciety-dm-profile4 > *:nth-child(2) {
  position: absolute;
  top: 0;
  right: 0;
}
.ciety-dm-profile4 > *:nth-child(3) {
  position: absolute;
  bottom: 0;
  left: 0;
}
.ciety-dm-profile4 > *:nth-child(4) {
  position: absolute;
  bottom: 0;
  right: 0;
}

/* alert, confirm */
.modal-container {
  @apply fixed left-0 top-0 flex h-full w-full items-center justify-center bg-ciety-dk/90;
  z-index: var(--modal-z-index);
}

.modal-wrapper {
  @apply mx-5 flex flex-col rounded-[2.5rem] bg-ciety-dk py-5;
  width: 23.75rem;
}

.modal-button {
  @apply text-[1.125rem] text-ciety-wh;
}

.modal-content {
  @apply flex min-h-[10rem] items-center justify-center whitespace-pre-wrap px-5 text-center text-[0.875rem] font-normal tracking-normal text-ciety-wh;
  word-break: break-word;
}

.modal-button-wrapper {
  @apply flex py-5;
}

.modal-button {
  @apply w-full font-medium leading-[1.688rem] tracking-normal;
}

.modal-button.text-green {
  @apply text-ciety-lg;
}

.modal-button.text-red {
  @apply text-ciety-rd;
}

.modal-button.text-white {
  @apply text-ciety-wh;
}

.modal-button.text-gray {
  @apply text-ciety-gy;
}

#confirm-modal .modal-button {
  @apply text-[1rem] font-normal;
}

.sortable-drag-channel-preview {
  height: 2.5rem;
  overflow: hidden;
}

.sns-thumbnail-type-swiper .swiper-slide {
  /* 281 / 375 */
  width: 58.13%;
}
