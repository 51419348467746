/** 수동 작업 X, ./scripts/gen-img-comp.mjs 파일을 참고해주세요. **/

.community-image-c_default_cover_0_skip {
  background-image: image-set(
    url("./assets/C-Default-Cover-0-Skip@2x.png") 2x,
    url("./assets/C-Default-Cover-0-Skip@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_cover_1_game {
  background-image: image-set(
    url("./assets/C-Default-Cover-1-Game@2x.png") 2x,
    url("./assets/C-Default-Cover-1-Game@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_cover_10_food {
  background-image: image-set(
    url("./assets/C-Default-Cover-10-Food@2x.png") 2x,
    url("./assets/C-Default-Cover-10-Food@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_cover_11_pet {
  background-image: image-set(
    url("./assets/C-Default-Cover-11-Pet@2x.png") 2x,
    url("./assets/C-Default-Cover-11-Pet@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_cover_12_music {
  background-image: image-set(
    url("./assets/C-Default-Cover-12-Music@2x.png") 2x,
    url("./assets/C-Default-Cover-12-Music@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_cover_13_sports {
  background-image: image-set(
    url("./assets/C-Default-Cover-13-Sports@2x.png") 2x,
    url("./assets/C-Default-Cover-13-Sports@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_cover_14_it {
  background-image: image-set(
    url("./assets/C-Default-Cover-14-IT@2x.png") 2x,
    url("./assets/C-Default-Cover-14-IT@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_cover_15_writing {
  background-image: image-set(
    url("./assets/C-Default-Cover-15-Writing@2x.png") 2x,
    url("./assets/C-Default-Cover-15-Writing@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_cover_16_travel {
  background-image: image-set(
    url("./assets/C-Default-Cover-16-Travel@2x.png") 2x,
    url("./assets/C-Default-Cover-16-Travel@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_cover_17_art {
  background-image: image-set(
    url("./assets/C-Default-Cover-17-Art@2x.png") 2x,
    url("./assets/C-Default-Cover-17-Art@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_cover_18_faith {
  background-image: image-set(
    url("./assets/C-Default-Cover-18-Faith@2x.png") 2x,
    url("./assets/C-Default-Cover-18-Faith@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_cover_19_fashion {
  background-image: image-set(
    url("./assets/C-Default-Cover-19-Fashion@2x.png") 2x,
    url("./assets/C-Default-Cover-19-Fashion@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_cover_2_photography {
  background-image: image-set(
    url("./assets/C-Default-Cover-2-Photography@2x.png") 2x,
    url("./assets/C-Default-Cover-2-Photography@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_cover_3_lifestyle {
  background-image: image-set(
    url("./assets/C-Default-Cover-3-Lifestyle@2x.png") 2x,
    url("./assets/C-Default-Cover-3-Lifestyle@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_cover_4_movie {
  background-image: image-set(
    url("./assets/C-Default-Cover-4-Movie@2x.png") 2x,
    url("./assets/C-Default-Cover-4-Movie@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_cover_5_economy {
  background-image: image-set(
    url("./assets/C-Default-Cover-5-Economy@2x.png") 2x,
    url("./assets/C-Default-Cover-5-Economy@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_cover_6_education {
  background-image: image-set(
    url("./assets/C-Default-Cover-6-Education@2x.png") 2x,
    url("./assets/C-Default-Cover-6-Education@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_cover_7_kpop {
  background-image: image-set(
    url("./assets/C-Default-Cover-7-Kpop@2x.png") 2x,
    url("./assets/C-Default-Cover-7-Kpop@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_cover_8_politic {
  background-image: image-set(
    url("./assets/C-Default-Cover-8-Politic@2x.png") 2x,
    url("./assets/C-Default-Cover-8-Politic@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_cover_9_science {
  background-image: image-set(
    url("./assets/C-Default-Cover-9-Science@2x.png") 2x,
    url("./assets/C-Default-Cover-9-Science@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_gatebg_0_skip {
  background-image: image-set(
    url("./assets/C-Default-GateBG-0-Skip@2x.png") 2x,
    url("./assets/C-Default-GateBG-0-Skip@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_gatebg_1_game {
  background-image: image-set(
    url("./assets/C-Default-GateBG-1-Game@2x.png") 2x,
    url("./assets/C-Default-GateBG-1-Game@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_gatebg_10_food {
  background-image: image-set(
    url("./assets/C-Default-GateBG-10-Food@2x.png") 2x,
    url("./assets/C-Default-GateBG-10-Food@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_gatebg_11_pet {
  background-image: image-set(
    url("./assets/C-Default-GateBG-11-Pet@2x.png") 2x,
    url("./assets/C-Default-GateBG-11-Pet@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_gatebg_12_music {
  background-image: image-set(
    url("./assets/C-Default-GateBG-12-Music@2x.png") 2x,
    url("./assets/C-Default-GateBG-12-Music@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_gatebg_13_sports {
  background-image: image-set(
    url("./assets/C-Default-GateBG-13-Sports@2x.png") 2x,
    url("./assets/C-Default-GateBG-13-Sports@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_gatebg_14_it {
  background-image: image-set(
    url("./assets/C-Default-GateBG-14-IT@2x.png") 2x,
    url("./assets/C-Default-GateBG-14-IT@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_gatebg_15_writing {
  background-image: image-set(
    url("./assets/C-Default-GateBG-15-Writing@2x.png") 2x,
    url("./assets/C-Default-GateBG-15-Writing@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_gatebg_16_travel {
  background-image: image-set(
    url("./assets/C-Default-GateBG-16-Travel@2x.png") 2x,
    url("./assets/C-Default-GateBG-16-Travel@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_gatebg_17_art {
  background-image: image-set(
    url("./assets/C-Default-GateBG-17-Art@2x.png") 2x,
    url("./assets/C-Default-GateBG-17-Art@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_gatebg_18_faith {
  background-image: image-set(
    url("./assets/C-Default-GateBG-18-Faith@2x.png") 2x,
    url("./assets/C-Default-GateBG-18-Faith@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_gatebg_19_fashion {
  background-image: image-set(
    url("./assets/C-Default-GateBG-19-Fashion@2x.png") 2x,
    url("./assets/C-Default-GateBG-19-Fashion@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_gatebg_2_photgraphy {
  background-image: image-set(
    url("./assets/C-Default-GateBG-2-Photgraphy@2x.png") 2x,
    url("./assets/C-Default-GateBG-2-Photgraphy@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_gatebg_3_lifestyle {
  background-image: image-set(
    url("./assets/C-Default-GateBG-3-Lifestyle@2x.png") 2x,
    url("./assets/C-Default-GateBG-3-Lifestyle@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_gatebg_4_movie {
  background-image: image-set(
    url("./assets/C-Default-GateBG-4-Movie@2x.png") 2x,
    url("./assets/C-Default-GateBG-4-Movie@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_gatebg_5_economy {
  background-image: image-set(
    url("./assets/C-Default-GateBG-5-Economy@2x.png") 2x,
    url("./assets/C-Default-GateBG-5-Economy@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_gatebg_6_education {
  background-image: image-set(
    url("./assets/C-Default-GateBG-6-Education@2x.png") 2x,
    url("./assets/C-Default-GateBG-6-Education@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_gatebg_7_kpop {
  background-image: image-set(
    url("./assets/C-Default-GateBG-7-Kpop@2x.png") 2x,
    url("./assets/C-Default-GateBG-7-Kpop@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_gatebg_8_politic {
  background-image: image-set(
    url("./assets/C-Default-GateBG-8-Politic@2x.png") 2x,
    url("./assets/C-Default-GateBG-8-Politic@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_gatebg_9_science {
  background-image: image-set(
    url("./assets/C-Default-GateBG-9-Science@2x.png") 2x,
    url("./assets/C-Default-GateBG-9-Science@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_profile_0_skip {
  background-image: image-set(
    url("./assets/C-Default-Profile-0-Skip@2x.png") 2x,
    url("./assets/C-Default-Profile-0-Skip@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_profile_1_game {
  background-image: image-set(
    url("./assets/C-Default-Profile-1-Game@2x.png") 2x,
    url("./assets/C-Default-Profile-1-Game@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_profile_10_food {
  background-image: image-set(
    url("./assets/C-Default-Profile-10-Food@2x.png") 2x,
    url("./assets/C-Default-Profile-10-Food@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_profile_11_pet {
  background-image: image-set(
    url("./assets/C-Default-Profile-11-Pet@2x.png") 2x,
    url("./assets/C-Default-Profile-11-Pet@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_profile_12_music {
  background-image: image-set(
    url("./assets/C-Default-Profile-12-Music@2x.png") 2x,
    url("./assets/C-Default-Profile-12-Music@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_profile_13_sports {
  background-image: image-set(
    url("./assets/C-Default-Profile-13-Sports@2x.png") 2x,
    url("./assets/C-Default-Profile-13-Sports@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_profile_14_it {
  background-image: image-set(
    url("./assets/C-Default-Profile-14-IT@2x.png") 2x,
    url("./assets/C-Default-Profile-14-IT@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_profile_15_writing {
  background-image: image-set(
    url("./assets/C-Default-Profile-15-Writing@2x.png") 2x,
    url("./assets/C-Default-Profile-15-Writing@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_profile_16_travel {
  background-image: image-set(
    url("./assets/C-Default-Profile-16-Travel@2x.png") 2x,
    url("./assets/C-Default-Profile-16-Travel@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_profile_17_art {
  background-image: image-set(
    url("./assets/C-Default-Profile-17-Art@2x.png") 2x,
    url("./assets/C-Default-Profile-17-Art@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_profile_18_faith {
  background-image: image-set(
    url("./assets/C-Default-Profile-18-Faith@2x.png") 2x,
    url("./assets/C-Default-Profile-18-Faith@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_profile_19_fashion {
  background-image: image-set(
    url("./assets/C-Default-Profile-19-Fashion@2x.png") 2x,
    url("./assets/C-Default-Profile-19-Fashion@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_profile_2_photography {
  background-image: image-set(
    url("./assets/C-Default-Profile-2-Photography@2x.png") 2x,
    url("./assets/C-Default-Profile-2-Photography@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_profile_3_lifestyle {
  background-image: image-set(
    url("./assets/C-Default-Profile-3-Lifestyle@2x.png") 2x,
    url("./assets/C-Default-Profile-3-Lifestyle@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_profile_4_movie {
  background-image: image-set(
    url("./assets/C-Default-Profile-4-Movie@2x.png") 2x,
    url("./assets/C-Default-Profile-4-Movie@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_profile_5_economy {
  background-image: image-set(
    url("./assets/C-Default-Profile-5-Economy@2x.png") 2x,
    url("./assets/C-Default-Profile-5-Economy@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_profile_6_education {
  background-image: image-set(
    url("./assets/C-Default-Profile-6-Education@2x.png") 2x,
    url("./assets/C-Default-Profile-6-Education@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_profile_7_kpop {
  background-image: image-set(
    url("./assets/C-Default-Profile-7-Kpop@2x.png") 2x,
    url("./assets/C-Default-Profile-7-Kpop@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_profile_8_politic {
  background-image: image-set(
    url("./assets/C-Default-Profile-8-Politic@2x.png") 2x,
    url("./assets/C-Default-Profile-8-Politic@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.community-image-c_default_profile_9_science {
  background-image: image-set(
    url("./assets/C-Default-Profile-9-Science@2x.png") 2x,
    url("./assets/C-Default-Profile-9-Science@3x.png") 3x
  );
  background-repeat: no-repeat;
  background-size: contain;
}
